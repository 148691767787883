<template>
  <v-container class="content ma-0 pa-4">
    <v-row v-if="!loading">
      <v-row>
        <v-col>
          <DateInputs
            ref="dataInputRelatorio"
            v-model="relatorios"
            :dashboard="getDashboard"
            @searching="loading"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <base-material-stats-card
            :title="item.title"
            :icon="item.icon"
            :value="item.value"
            :color="item.color"
            :sub-icon="item.subicon"
            :sub-icon-color="item.subiconcolor"
            :sub-text="item.subtext"
            :elevation="item.elevation"
            :action="item.action"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" lg="12" xl="12" class="grafico">
          <base-material-chart-card
            :data="compvisitas.data"
            :options="optionsBar"
            type="Line"
            color="primary"
            title="Comparativo de Relatórios Realizados (Último Mês x Mês Atual)"
            icon="mdi-chart-line"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            sub-text-left="Últimos 30 dias"
            elevation="6"
          />
        </v-col>
      </v-row>
    </v-row>
    <v-row
      v-else
      justify="center"
      align="center"
      class="pa-4"
      style="height: 50vh"
    >
      <div style="text-align: center">
        <v-progress-circular :size="70" color="primary" indeterminate />
        <h3 class="mt-4">Preparando visualização...</h3>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Tooltips from 'chartist-plugin-tooltips-updated'
import Legenda from 'chartist-plugin-axistitle'
import DateInputs from './DateInputs'
import moment from 'moment'

export default {
  name: 'DashboardN',
  components: {
    DateInputs,
  },
  data() {
    return {
      loading: true,
      dashboard: null,
      dataInicio: moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM'),
      items: [],
      relatorios: [],
      compvisitas: null,
      optionsBar: {
        axisX: {
          offset: 30,
        },
        axisY: {
          onlyInteger: true,
          offset: 50,
        },
        lineSmooth: this.$chartist.Interpolation.monotoneCubic({
          tension: 0,
        }),
        height: '100%',
        low: 0,
        showArea: false,
        plugins: [
          Tooltips({
            appendToBody: false,
            tooltipFnc: function (meta, value) {
              return meta + '<br />' + value + ' Relatórios'
            },
          }),
          Legenda({
            axisX: {
              axisTitle: 'Dias do Mês',
              axisClass: 'ct-axis-title',
              offset: { x: 0, y: 40 },
              textAnchor: 'middle',
            },
            axisY: {
              axisTitle: 'Nº de Relatórios',
              axisClass: 'ct-axis-title',
              offset: { x: 0, y: -1 },
              flipTitle: false,
            },
          }),
        ],
        chartPadding: {
          top: 30,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    }
  },
  computed: {
    ...mapState({ user: state => state.user }),
  },
  mounted() {
    this.getDashboard(this.dataInicio)
  },
  methods: {
    relVisitaGeral() {
      this.$router.push({ path: 'relatorios/geral' }).catch(() => {})
    },
    relCCS() {
      this.$router.push({ path: 'relatorios/ccs' }).catch(() => {})
    },
    relCPP() {
      this.$router.push({ path: 'relatorios/cpp' }).catch(() => {})
    },
    relEvolui() {
      this.$router.push({ path: 'relatorios/evoluileite' }).catch(() => {})
    },
    relBoasPraticas() {
      this.$router.push({ path: 'relatorios/boaspraticas' }).catch(() => {})
    },
    relFraude() {
      this.$router.push({ path: 'relatorios/fraudes' }).catch(() => {})
    },
    relBonolat() {
      this.$router.push({ path: 'relatorios/bonolat' }).catch(() => {})
    },
    relEducampo() {
      this.$router.push({ path: 'relatorios/educampo' }).catch(() => {})
    },
    fazendas() {
      this.$router.push({ path: 'fazendas' }).catch(() => {})
    },
    getDashboard(dataInicio) {
      this.loading = true
      this.api.get.dashboard(dataInicio).then(data => {
        this.compvisitas = data.grafico1
        this.items = []
        this.items.push({
          title: 'Fazendas',
          icon: 'mdi-home-flood',
          value: String(data.QtdFazendas),
          color: 'success',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Atendidas até o momento',
          elevation: '6',
          action: this.fazendas,
        })

        const cardReports = [
          {
            id: '1',
            title: 'Relatórios Visita Geral',
            icon: 'mdi-clipboard-list',
            value: String(data.QtdRelVisita),
            color: 'primary',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relVisitaGeral,
          },
          {
            id: '2',
            title: 'Relatórios CCS',
            icon: 'mdi-clipboard-text',
            value: String(data.QtdRelCCS),
            color: 'lime darken-2',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relCCS,
          },
          {
            id: '3',
            title: 'Relatórios CPP',
            icon: 'mdi-clipboard-edit',
            value: String(data.QtdRelCPP),
            color: 'red darken-4',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relCPP,
          },
          {
            id: '4',
            title: 'Relatório de Evolui Leite',
            icon: 'mdi-clipboard-pulse',
            value: String(data.QtdRelEvolui),
            color: 'purple darken-4',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relEvolui,
          },
          {
            id: '5',
            title: 'Relatórios Boas Práticas',
            icon: 'mdi-clipboard-text-multiple',
            value: String(data.QtdBoasPraticas),
            color: 'teal darken-4',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relBoasPraticas,
          },
          {
            id: '6',
            title: 'Relatórios de Resíduos de ATB',
            icon: 'mdi-clipboard-file',
            value: String(data.QtdRelATB),
            color: 'pink darken-4',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relATB,
          },
          {
            id: '7',
            title: 'Relatórios de fraudes',
            icon: 'mdi-clipboard-text-play',
            value: String(data.QtdRelFraudes),
            color: 'yellow darken-2',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relFraude,
          },
          {
            id: '8',
            title: 'Relatório de visita Bonolat',
            icon: 'mdi-clipboard-file',
            value: String(data.QtdRelBonolat),
            color: 'grey darken-3',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relBonolat,
          },
          {
            id: '9',
            title: 'Relatório Educampo',
            icon: 'mdi-clipboard-multiple',
            value: String(data.QtdRelEducampo),
            color: 'pink darken-2',
            subicon: 'mdi-clock-outline',
            subiconcolor: 'info',
            subtext: 'Realizados até o momento',
            elevation: '6',
            action: this.relEducampo,
          },
        ]

        const user = this.$store.state.user

        if (user?.permissions.length === 0) {
          cardReports.forEach(item => {
            this.items.push(item)
          })
        } else if (user?.permissions.length > 0) {
          const relatorios = cardReports.filter(item =>
            user.permissions.find(p => Number(p.report_id) === Number(item.id)),
          )

          relatorios.forEach(item => {
            this.items.push(item)
          })
        } else {
          const user = JSON.parse(localStorage.getItem('user'))

          if (user?.permissions.length > 0) {
            const relatorios = cardReports.filter(item =>
              user.permissions.find(
                p => Number(p.report_id) === Number(item.id),
              ),
            )

            relatorios.forEach(item => {
              this.items.push(item)
            })
          }
        }

        this.loading = false
      })
    },
  },
}
</script>

<style>
.grafico .ct-square {
  height: 300px;
}

.grafico .v-card--material-chart .v-card--material__heading {
  max-height: 330px;
}

.grafico .v-card--material-chart .theme--dark.v-card {
  background-color: white !important;
}

.grafico .ct-labels {
  fill: '#143693';
}
</style>
